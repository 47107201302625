:root {
  --header-background: #efefef;
}

@keyframes toggle-modal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

dialog {
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-width: 0;
  border-radius: var(--border-radius);
  border-color: white;
  padding: 15px;
}

dialog.small {
  min-height: 100px;
  min-width: 170px;
}

dialog.large {
  min-height: 600px;
  min-width: 400px;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

dialog:not([open]) {
  display: none;
}

dialog[open] {
  animation: toggle-modal 0.2s ease-in-out;
}

dialog header {
  background-color: var(--header-background);

  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
}

dialog header .close {
  position: absolute;
  right: 0;
  top: 0;

  width: 50px;
  height: 50px;
}

dialog textarea {
  height: 100%;
  flex-grow: 1;
}

.config-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.config-group input {
  width: 50px;
}

dialog.error p {
  max-width: 400px;
}
